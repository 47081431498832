// import picturefill from 'picturefill';

export const onClientEntry = async () => {
   // picturefill();
   // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
   document.createElement('picture');
   if (typeof window.IntersectionObserver === 'undefined') {
      import('intersection-observer');
   }
   if (!window.Intl) {
      require.ensure(
         [
            'intl',
            'intl/locale-data/jsonp/en.js',
            'intl/locale-data/jsonp/cs.js',
         ],
         require => {
            require('intl');
            require('intl/locale-data/jsonp/en.js');
            require('intl/locale-data/jsonp/cs.js');
         },
      );
   }
};
